import { instance } from "../apiBase/instance";

export const AquaAdmin = {
    loginAdmin(email:any , password: any) {
        let params = {
            email: email,
            password: password,
        };
        return instance.post<any>(`user/login`, params);
    },
    getRatioSetting() {
        return instance.get<any>(`user/ratio`);
    },
    postRatioSetting(wallets:any ,valueQuery:any , timeFrom:any ,timeTo:any) {
        let params = {
            ownerAddress: wallets,
            amount: Number(valueQuery),
            defaultAmount: 0,
            applyFrom:timeFrom,
            applyTo:timeTo,
        };
        return instance.post<any>(`user/setRatio` , params);
    },
    postUploadWhitelist(formData:any) {
        return instance.post<any>(`user/upload_whitelist`, formData , {
            headers: {
                "content-type": "multipart/form-data",
            },
        });
    },
    getWhitelist(params:any) {
        return instance.post<any>(`user/whitelist` , params);
    },
    postWhitelistByHand(params:any) {
        return instance.post<any>(`user/setWhitelist` , params);
    },
    postUpdateStatus(params:any) {
        return instance.post<any>(`user/updateWhitelist` , params);
    },
    getIfnoPool() {
        return instance.get<any>(`user/pool_info`);
    },
    getRoof(params:any) {
        return instance.post<any>(`user/get_root` , params);
    },
    getBtnStatus() {
        return instance.get<any>(`user/is_show_btn`);
    },
    postHistory(params:any) {
        return instance.post<any>(`user/history_vesting` , params);
    },
}