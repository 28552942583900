import React, { useState, useEffect } from 'react';
import { AquaAdmin } from '../../../api/aquaApi';
import { useLocation } from 'react-router-dom';
import { Empty , Pagination} from 'antd';
import type { PaginationProps } from 'antd';
import moment from 'moment';

const HistoryVesting = () => {
    const location = useLocation()
    let pathUrl = location.search.split("?")
    let pathLive = pathUrl[1]
    const [dataHistory, setDataHistory] = useState([])
    const [countHistory, setCountHistory]:any = useState();
    const [current, setCurrent] = useState(1);

    const handleHistoryApi = async () => {
        let params:any = {
            pageIndex: 0,
            pageSize: 0,
            address: pathLive,
        };
        await AquaAdmin.postHistory(params).then((res: any) => {
            if (res.data.data) {
                setCountHistory(res.data.totalCount)
                setDataHistory(res.data.data)
            }
        }).catch((err: any) => {
            console.log('err', err)
        });
    };

    const onChange: PaginationProps['onChange'] = async (page) => {
        setCurrent(page);
        let params:any = {
            pageIndex: page - 1,
            pageSize: 0,
            address: "",
            type: -1,
        };
        await AquaAdmin.postHistory(params).then((res: any) => {
            if (res.data.data) {
                setCountHistory(res.data.totalCount)
                setDataHistory(res.data.data)
            }
        }).catch((err: any) => {
            console.log('err', err)
        });
    };

    useEffect(() => {
        handleHistoryApi()
    }, [pathLive]);

    const Item = ({item , index}:any) => {
        return (
            <>
                <tr>
                    <td>
                        {index + 1}
                    </td>
                    <td>
                        {item?.ownerAddress}
                    </td>
                    <td>
                        {item?.token}
                    </td>
                    <td>
                        {moment(item.vestingDate).format('MMMM Do YYYY, h:mm:ss')}
                    </td>
                </tr>
            </>
        )
    }
    return (
        <>
            <div className="wrap-container">
                <div className="row-import">
                    <div className="guide-import">
                        <div className="title">
                            History Vesting
                        </div>
                        <div className="data-table">
                            <table className="main-table">
                                
                                {dataHistory.length > 0 ?
                                    <>
                                        <tr>
                                            <th>
                                                Id
                                            </th>
                                            <th>
                                                Address
                                            </th>
                                            <th>
                                                Token
                                            </th>
                                            <th>
                                                Vesting date
                                            </th>
                                        </tr>
                                        {dataHistory && dataHistory.map((item: any, index: any) => (
                                            <Item item={item} index={index} />
                                        ))}
                                        {countHistory < 10 ? 
                                            <>
                                                {""}
                                            </>
                                            :
                                            <>
                                                <Pagination onChange={onChange} current={current} total={countHistory} />
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        <div className="non-data">
                                            <Empty />
                                        </div>
                                    </>
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HistoryVesting