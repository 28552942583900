import React, { useState , useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { AquaAdmin } from '../../api/aquaApi';
import { DatePicker, Space } from 'antd';
import { useWallet } from "@solana/wallet-adapter-react";
import moment from 'moment';
import {
    RollbackOutlined
   } from '@ant-design/icons';
import "./style.scss"

const EditSetting = () => {
    const navigate = useNavigate();
    const { RangePicker } = DatePicker;
    const [timeFrom , setTimeFrom] = useState()
    const [timeTo , setTimeTo] = useState()
    const [valueQuery , setValueQuery] = useState(0)
    const { publicKey }: any = useWallet();
    let wallets = publicKey?.toString()

    const handleChangeTime = (value:any, dateString:any) => {
        setTimeFrom(dateString[0])
        setTimeTo(dateString[1])
    }

    const changeValue = (e:any) => {
        setValueQuery(e.target.value)
    }

    const handleSubmit = async () => {
        await AquaAdmin.postRatioSetting(wallets , valueQuery , moment(timeFrom).format() ,moment(timeTo).format()).then((res: any) => {
            if (res.data.isSuccess === false) {
                navigate(`/login`)
            } else {
                if (valueQuery > 0) {
                    navigate(`/setting`)
                }
            }
        }).catch((err: any) => {
            console.log('err', err)
        });
    }

    const handleBack = () => {
        navigate(`/setting`)
    }

    return (
        <>
            <div className="wrap-container">
                <div className="row-import">
                    <div className="guide-import">
                        <div className="back-setting" onClick={handleBack}>
                            <RollbackOutlined /> Back
                        </div>
                        <div className="title">
                            Edit Setting
                        </div>
                        <div className="box-round">
                            <div className="txt">
                                Set claim token
                            </div>
                            <div className="item">
                                <div className="text">
                                    Amount
                                </div>
                                <div className="form-input">
                                    <div className="right">
                                        <input type="text" className="f-form" onChange={changeValue} />
                                        <div className="name-balance">
                                            Sol
                                        </div>
                                    </div>
                                    <div className="left">
                                        <input type="text" value={1} className="f-form" />
                                        <div className="name-balance">
                                            Aqua
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="text">
                                    Apply Time
                                </div>
                                <div className="form-input">
                                    <RangePicker
                                        showTime={{ format: 'HH:mm' }}
                                        format="YYYY-MM-DD HH:mm:ss"
                                        onChange={handleChangeTime}
                                    />
                                </div>
                            </div>
                            <div className="gr-button">
                                {valueQuery > 0 ?
                                    <>
                                        <button className="btn-edit" style={{marginRight: "10px"}} onClick={handleSubmit}>
                                            Confirm
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button className="btn-edit dis" style={{marginRight: "10px"}}>
                                            Confirm
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditSetting