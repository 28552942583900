import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Empty, Button } from 'antd';
import { AquaAdmin } from '../../api/aquaApi';
import {
    EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import "./style.scss"

const Setting = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([])

    const handleCreate = () => {
        navigate(`/edit-setting`)
    }

    const getRatioSetting = async () => {
        await AquaAdmin.getRatioSetting().then((res: any) => {
            if (res.data.isSuccess === false) {
                navigate(`/login`)
            } else {
                if (res.data.data) {
                    setData(res.data.data)
                }
            }
        }).catch((err: any) => {
            console.log('err', err)
        });
    }

    useEffect(() => {
        getRatioSetting()
    }, []);

    const Item = ({ item, index }: any) => {
        return (
            <>
                <tr>
                    <td>
                        {index + 1}
                    </td>
                    <td>
                        1 Aqua = {item?.price} Sol
                    </td>
                    <td>
                        {moment(item.fromDate).format('MMMM Do YYYY, h:mm:ss')}
                    </td>
                    <td>
                        {moment(item.toDate).format('MMMM Do YYYY, h:mm:ss')}
                    </td>
                    <td>
                        <div className="button-action">
                            <button className="btn-action" onClick={handleCreate}>
                                <EditOutlined />
                            </button>
                        </div>
                    </td>
                </tr>
            </>
        )
    }

    return (
        <>
            <div className="wrap-container">
                <div className="row-import">
                    <div className="guide-import">
                        <div className="title">
                            Setting
                        </div>
                        <div className="box-round">
                            {data.length > 0 ?
                                <>
                                    <div className="data-table">
                                        <table className="main-table">
                                            <tr>
                                                <th>
                                                    ID
                                                </th>
                                                <th>
                                                    Price
                                                </th>
                                                <th>
                                                    Apply From
                                                </th>
                                                <th>
                                                    Apply To
                                                </th>
                                                <th>
                                                    Action
                                                </th>
                                            </tr>
                                            {data && data.map((item: any, index: any) => (
                                                <Item item={item} index={index} />
                                            ))}
                                        </table>
                                    </div>

                                </>
                                :
                                <>
                                    <div className="non-data">
                                        <Empty
                                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                            imageStyle={{ height: 60 }}
                                            description={
                                                <span>
                                                    No data
                                                </span>
                                            }
                                        >
                                            <div className="gr-button">
                                                <button className="btn-edit" onClick={handleCreate}>
                                                    Create
                                                </button>
                                            </div>
                                        </Empty>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Setting