import React from 'react';
import { UserAddOutlined , LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import {isMobile} from 'react-device-detect';
import "../style.scss"

const RoleAdmin = () => {
    const navigate = useNavigate()

    const handleLogout = () => {
        navigate(`/login`)
    }

    const handleLogin = () => {
        navigate(`/login`)
    }

    let nameUser = localStorage.getItem("userName")

    return (
        <>
            <div className="role-admin">
                {nameUser === null ?
                    <>
                        <div className="login-role" onClick={handleLogin}>
                            Login
                        </div>
                    </>
                    :
                    <>
                        <WalletMultiButton />
                        <div className="name-role">
                            {isMobile ? 
                                <>
                                    <UserAddOutlined />
                                </>
                                :
                                <>
                                    <UserAddOutlined /> <span style={{marginLeft: "10px"}}>{nameUser}</span>
                                </>
                            }
                            
                        </div>
                        <div className="logout-role" onClick={handleLogout}>
                            <LogoutOutlined />
                        </div>
                    </>
                }
            </div>
        </>
    )
}
export default RoleAdmin