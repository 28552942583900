import React , { useState } from 'react';
import { ShoppingCartOutlined, WindowsOutlined, ApartmentOutlined, ExportOutlined , BarsOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Link } from "react-router-dom";
import { Menu , Drawer } from 'antd';
import { isMobile } from 'react-device-detect';
import "../style.scss"

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const items: MenuProps['items'] = [
    getItem(
        <Link to="/">Dashboard</Link> , "4" ,<Link to="/"><WindowsOutlined /></Link>    
    ),
    getItem(
        <Link to="/import-wl">Import whitelist</Link> , "1" ,<Link to="/import-wl"><ShoppingCartOutlined /></Link>    
    ),
    getItem(
        <Link to="/setting">Setting</Link> , "2" ,  <Link to="/setting"><ApartmentOutlined /></Link>
    ),
    getItem(
        <Link to="/pool-info">Pool Info</Link> , "3" , <Link to="/pool-info"><ExportOutlined />  </Link>   
    ),
    // getItem(
    //     "IDO Pool" , "5" , <SendOutlined />    
    // ),
];


const Header = () => {
    const [current, setCurrent] = useState<any>("1");
    const onClick: MenuProps["onClick"] = (e) => {
        setCurrent(e.key);
        localStorage.setItem("keyActiveMenu", e.key);
        setOpen(false);
    };

    let keyActiveDrop: any;
    if (
        typeof window !== "undefined" &&
        window.localStorage.getItem("keyActiveMenu") !== undefined
    ) {
        keyActiveDrop = window.localStorage.getItem("keyActiveMenu");
    }

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            {isMobile ?
                <>
                    <button className="btn-bar" onClick={showDrawer}>
                        <BarsOutlined />
                    </button>
                    <Drawer className="menu-drawer" title="Menu admin" placement="right" onClose={onClose} open={open}>
                        <div className="main-menu">
                            <div className="box-logo">
                                <img src="./images/logo-aqua.png" alt="" />
                            </div>
                            <Menu
                                onClick={onClick}
                                style={{ width: 256 }}
                                defaultSelectedKeys={keyActiveDrop}
                                mode="inline"
                                items={items}
                            />
                        </div>
                    </Drawer>
                </>
                :
                <>
                    <header className="header">
                        <div className="main-header">
                            <div className="main-menu">
                                <div className="box-logo">
                                    <img src="./images/logo-aqua.png" alt="" />
                                </div>
                                <Menu
                                    onClick={onClick}
                                    style={{ width: 256 }}
                                    defaultSelectedKeys={keyActiveDrop}
                                    mode="inline"
                                    items={items}
                                />
                            </div>
                        </div>
                    </header>
                </>
            }
        </>
    )
}
export default Header