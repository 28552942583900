import React, { useState, useEffect } from 'react';
import { message, Spin } from 'antd';
import { ReloadOutlined, CopyOutlined, CheckCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useWallet } from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import { PublicKey, LAMPORTS_PER_SOL, Connection, SystemProgram, SYSVAR_RENT_PUBKEY } from "@solana/web3.js";
import { SOLANA_HOST } from "./const";
import claim_sol_aqua from "./abi/claim_sol_aqua.json";
import "./style.scss"
import { BN } from '@project-serum/anchor';
import { AquaAdmin } from '../../api/aquaApi';
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isMobile } from 'react-device-detect';

const connection = new Connection(SOLANA_HOST, {
    commitment: "confirmed",
});

declare const window: Window &
typeof globalThis & {
    solflare: any;
};

const PoolInfo = () => {
    var JSONB = require('json-buffer')
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [dataRoof, setDataRoof]: any = useState();
    const [dataBtn, setDataBtn]: any = useState();
    const { publicKey }: any = useWallet();
    const [status, setStatus] = useState(false);
    const [balance, setBalance]: any = useState(0);
    const [balanceMinimum, setBalanceMinimum]: any = useState(0);
    const [valueInput, setValueInput]: any = useState("");
    let wallets = publicKey?.toString()
    const [valueAddress, setValueAddress]: any = useState()
    const [loading, setLoading] = useState(false)
    const [valueSigner, setValueSigner]: any = useState()
    const [valuePost, setValuePost]: any = useState()
    const [loadingSign, setLoadingSign] = useState(false)
    const [addressDeposit, setAddressDeposit]:any = useState()

    const handleEnterSc = (e: any) => {
        setValueAddress(e.target.value)
    }

    const handleEnterSigner = (e: any) => {
        setValuePost(e.target.value)
    }

    const getPoolInfo = async () => {
        await AquaAdmin.getIfnoPool().then((res: any) => {
            if (res.data.isSuccess === false) {
                navigate(`/login`)
            } else {
                if (res.data.data) {
                    setData(res.data.data)
                }
            }
        }).catch((err: any) => {
            console.log('err', err)
        });
    }

    useEffect(() => {
        getPoolInfo()
    }, []);

    let scLocal: any = data

    // handle SC

    let IDO_PROGRAM_ID: any;
    if (scLocal !== undefined && scLocal !== null && scLocal !== "") {
        IDO_PROGRAM_ID = new PublicKey(scLocal);
    }

    const provider: any = new anchor.AnchorProvider(
        connection,
        window?.solana,
        anchor.AnchorProvider.defaultOptions()
    );

    let program: any;
    if (IDO_PROGRAM_ID && provider && scLocal !== undefined && scLocal !== null && scLocal !== "") {
        program = new anchor.Program<any>(claim_sol_aqua, IDO_PROGRAM_ID, provider);
    }

    let addressAdmin = new PublicKey("HGABrgetag7BbZoPSJGR3Y3L2KcXXCDomGX36b82FqEK")
    
    let signValue: any
    if (valuePost !== undefined && valuePost !== null && valuePost !== "" && valuePost?.length > 30) {
        signValue = new PublicKey(valuePost)
    }

    const getPoolResourcePDAInfo = async () => {
        return await PublicKey.findProgramAddress(
            [Buffer.from("aquaclaimpool"), addressAdmin?.toBuffer()],
            program.programId
        );
    }

    const onClickAddressSigner = async () => {
        const config = await getPoolResourcePDAInfo();
        try {
            await program.rpc.updatePoolAdmin(signValue, {
                accounts: {
                    poolclaim: config[0]?.toString(),
                    authority: wallets,
                },
            }).then(async (res: any) => {
                if (res) {
                    window.location.reload()
                }
            }).catch((err: any) => {
                message.error(err?.message || 'Fail')
            })
        } catch (error: any) {
            message.error(error?.message || 'Fail')
            console.log('error', error)
        }
    }

    const handleChangeStatus = async () => {
        let valueParse
        switch (status) {
            case true:
                valueParse = false;
                break;
            case false:
                valueParse = true;
                break;
        }
        const config = await getPoolResourcePDAInfo();
        await program.rpc.updatePoolclaim(valueParse, {
            accounts: {
                poolclaim: config[0]?.toString(),
                authority: wallets,
            },
        }).then(async (res: any) => {
            if (res) {
                await program.account.poolResource.fetch(config[0]?.toString()).then((resAny: any) => {
                    setStatus(resAny.poolStatus)
                    window.location.reload()
                })
            }
        }).catch((err: any) => {
            console.log('err', err)
        })
    }

    const getShowBtn = async () => {
        await AquaAdmin.getBtnStatus().then((res: any) => {
            if (res.data.isSuccess === false) {
                navigate(`/login`)
            } else {
                if (res.data.data) {
                    setDataBtn(res.data.data.isShowBtnRoot)
                }
            }
        }).catch((err: any) => {
            console.log('err', err)
        });
    }

    useEffect(() => {
        getShowBtn()
    }, []);


    const getMerkleTreePDA = async () => {
        return await PublicKey.findProgramAddress(
            [Buffer.from("aquamerkletree"), addressAdmin?.toBuffer()],
            program.programId
        );
    }

    const handleGenSign = async () => {
        setLoadingSign(true)
        let params = {
            ownerAddress: wallets
        }
        await AquaAdmin.getRoof(params).then( async (res: any) => {
            if (res.data.isSuccess === false) {
                navigate(`/login`)
            } else {
                if  (res.data.data) {
                    const mData = res.data.data;
                    const [merkleTreePDA, ___] = await getMerkleTreePDA();
                    const config = await getPoolResourcePDAInfo();
                    let dataRoofConvert = JSONB.parse(mData)
                    await program.rpc.setMerkleTreeData(
                        dataRoofConvert,
                        {
                            accounts: {
                                authority: wallets,
                                poolclaim: config[0]?.toString(),
                                merkleTree: merkleTreePDA, 
                                systemProgram: SystemProgram.programId,
                            },
                            signers: []
                        },
                    ).then((res: any) => {
                        setLoadingSign(false)
                        message.success('Gen sign success')
                    }).catch((err: any) => {
                        setLoadingSign(false)
                        console.log('err', err)
                        message.error(err?.message || 'Fail')
                    })
                }
            }
        }).catch((err: any) => {
            console.log('err', err)
        });

    }

    const handleWithDraw = async () => {
        setLoading(true)
        const config = await getPoolResourcePDAInfo();
        let amount = new BN(Number(valueInput) * anchor.web3.LAMPORTS_PER_SOL);
        try {
            await program.rpc.admWithdrawSol(amount, {
                accounts: {
                    poolclaim: config[0]?.toString(),
                    authority: wallets,
                },
            }).then(async (res: any) => {
                if (res) {
                    window.location.reload()
                    setLoading(false)
                }
            }).catch((err: any) => {
                message.error(err?.message || 'Fail')
                setLoading(false)
            })
        } catch (error: any) {
            message.error(error?.message || 'Fail')
            console.log('error', error)
        }
    }

    const handleFetch = async () => {
        const configRow = await getPoolResourcePDAInfo();
        if (program && publicKey && configRow[0]) {
            await program?.account?.poolResource?.fetch(configRow[0]?.toString()).then((resRow: any) => {
                setValueSigner(resRow.admin.toString())
                setStatus(resRow?.poolStatus)
            }).catch((err: any) => {
                console.log('err', err)
            })
        }
    }

    useEffect(() => {
        if (program && publicKey) {
            handleFetch()
        }
    }, [program, publicKey, status, valueSigner]);

    const getBalanceMinimun = async () => {
        (async () => {
            const dataSize = 42; // Replace with the desired account size in bytes
            const minBalance = await connection.getMinimumBalanceForRentExemption(dataSize);
            setBalanceMinimum(Number(minBalance / LAMPORTS_PER_SOL))
        })();
    };

    const getBalanceSOL = async () => {
        const config: any = await getPoolResourcePDAInfo();
        setAddressDeposit(config[0]?.toString())
        if (program && connection && config) {
            let x = new PublicKey(config[0]?.toString())
            await connection.getBalance(x)
            .then((res: any) => {
                setBalance(res / LAMPORTS_PER_SOL);
            });
        }
    };

    const handleValueMax = (e: any) => {
        setValueInput(e.target.value)
    }

    const handleEnterMax = () => {
        setValueInput(balance - balanceMinimum)
    }

    useEffect(() => {
        if (program && publicKey) {
            getBalanceSOL()
            getBalanceMinimun()
        }
    }, [balance, publicKey, program, addressDeposit]);

    return (
        <>
            <div className="wrap-container">
                <div className="row-import">
                    <div className="guide-import res">
                        {wallets !== undefined ?
                            <>
                                <div className="title">
                                    Pool Info
                                </div>
                                <div className="group-sc">
                                    <div className="txt">
                                        Contract Address
                                    </div>
                                    <div className="input-sc">
                                        <div className="f-control-round">
                                            <input type="text" value={scLocal !== undefined ? scLocal : valueAddress} onChange={handleEnterSc} placeholder="Enter contract adress" className="f-control-sc" />
                                        </div>
                                    </div>
                                    <div className="row-signer">
                                        <div className="txt">
                                            Update Admin Address
                                        </div>
                                        <div className="input-sc res">
                                            <div className="f-control-round">
                                                <input type="text" value={valuePost} onChange={handleEnterSigner} placeholder="Enter contract adress" className="f-control-sc" />
                                            </div>
                                            {valuePost !== null && valuePost !== "" && valuePost !== undefined ?
                                                <>
                                                    <div className="checked-sc" onClick={onClickAddressSigner}>
                                                        <CheckCircleOutlined />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="checked-sc row">
                                                        <CheckCircleOutlined />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {scLocal !== undefined && scLocal !== null && scLocal !== "" ?
                                        <>
                                            <div className="pool-contract">
                                                <div className="name">
                                                    Contract Info
                                                </div>
                                                <div className="item">
                                                    <div className="left">
                                                        Contract Address:
                                                    </div>
                                                    <div className="right">
                                                        {isMobile ? `${scLocal?.substring(0, 4)}...${scLocal?.substring(scLocal.length - 4)}` : scLocal}
                                                        <CopyToClipboard
                                                            text={`${scLocal}`}
                                                            onCopy={() => {
                                                                message.success({
                                                                    type: "success",
                                                                    content: `Copied success`,
                                                                    className: "custom-class",
                                                                    duration: 2,
                                                                });
                                                            }}
                                                        >
                                                            <span className="btn-copy"><CopyOutlined /></span>
                                                        </CopyToClipboard>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="left">
                                                        Admin Address:
                                                    </div>
                                                    <div className="right">
                                                        {isMobile ? `${valueSigner?.substring(0, 4)}...${valueSigner?.substring(valueSigner.length - 4)}` : valueSigner === undefined ? "NA" : valueSigner}
                                                        <CopyToClipboard
                                                            text={`${valueSigner}`}
                                                            onCopy={() => {
                                                                message.success({
                                                                    type: "success",
                                                                    content: `Copied success`,
                                                                    className: "custom-class",
                                                                    duration: 2,
                                                                });
                                                            }}
                                                        >
                                                            <span className="btn-copy"><CopyOutlined /></span>
                                                        </CopyToClipboard>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="left">
                                                        Deposit Pool Address:
                                                    </div>
                                                    <div className="right">
                                                        {isMobile ? `${addressDeposit?.substring(0, 4)}...${addressDeposit?.substring(addressDeposit.length - 4)}` : addressDeposit}
                                                        <CopyToClipboard
                                                            text={`${addressDeposit}`}
                                                            onCopy={() => {
                                                                message.success({
                                                                    type: "success",
                                                                    content: `Copied success`,
                                                                    className: "custom-class",
                                                                    duration: 2,
                                                                });
                                                            }}
                                                        >
                                                            <span className="btn-copy"><CopyOutlined /></span>
                                                        </CopyToClipboard>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="left">
                                                        Balance:
                                                    </div>
                                                    <div className="right">
                                                        {balance > 0 ? balance - balanceMinimum : 0} Sol
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="left">
                                                        Status:
                                                    </div>
                                                    <div className="right">
                                                        {status ? "Open" : "Close"} <span onClick={handleChangeStatus}><ReloadOutlined /></span>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="left">
                                                        Gen sign:
                                                    </div>
                                                    <div className="right">
                                                        {wallets ?
                                                            <>
                                                                {loadingSign ?
                                                                    <>
                                                                        <button className="gen-sign-btn dis">
                                                                            Gen Sign <Spin />
                                                                        </button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <button onClick={handleGenSign} className="gen-sign-btn">
                                                                            {dataBtn ?
                                                                                <>
                                                                                    {"Gen Sign"}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {"Gen Signed"}
                                                                                </>
                                                                            }
                                                                        </button>
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <button className="gen-sign-btn dis">
                                                                    Gen Sign
                                                                </button>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="withdraw-sc">
                                                <div className="name">
                                                    Withdraw contract
                                                </div>
                                                <div className="input-withraw">
                                                    <input type="text" value={valueInput} onChange={handleValueMax} className="f-control-wt" placeholder="Enter amount" />
                                                    <button className="btn-max" onClick={handleEnterMax}>
                                                        Max
                                                    </button>
                                                </div>
                                                <div className="row-btn-wt">
                                                    {Number(valueInput) > 0 ?
                                                        <>
                                                            {loading ?
                                                                <>
                                                                    <button className="btn-wh dis">
                                                                        Withdraw <Spin />
                                                                    </button>
                                                                </>
                                                                :
                                                                <>
                                                                    <button className="btn-wh" onClick={handleWithDraw}>
                                                                        Withdraw
                                                                    </button>
                                                                </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <button className="btn-wh dis">
                                                                Withdraw
                                                            </button>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {""}
                                        </>
                                    }
                                </div>
                            </>
                            :
                            <>
                                <div className="title-connect">
                                    Please connect wallet
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default PoolInfo