import React, { useState, useEffect } from 'react';
import "./style.scss"
import { DownloadOutlined, UploadOutlined, CheckCircleOutlined, CloseCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import { Button, Pagination, Upload, Empty, Modal, Input, Select, message } from 'antd';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import type { PaginationProps } from 'antd';
import { AquaAdmin } from '../../api/aquaApi';
import { useNavigate } from 'react-router-dom';
import type { SearchProps } from 'antd/es/input/Search';
import { useWallet } from "@solana/wallet-adapter-react";
import {
    EditOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import moment from 'moment';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const ImportWL = () => {
    const { Search } = Input;
    const { Option } = Select;
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const [current, setCurrent] = useState(1);
    const [count, setCount]: any = useState();
    const [valueWallet, setValueWallet]: any = useState("");
    const [valueHash, setValueHash]: any = useState("");
    const [valueAmount, setValueAmount]: any = useState("");
    const [valueAction, setValueAction]: any = useState("");
    const [valueSelect, setValueSelect]: any = useState("0");
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const { publicKey }: any = useWallet();
    let wallets = publicKey?.toString()

    const props: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    const handleUpload = async () => {
        if (wallets) {
            const formData: any = new FormData();
            formData.append('file', fileList[0] as FileType);
            formData.append('ownerAddress', wallets);
            setUploading(true);
            await AquaAdmin.postUploadWhitelist(formData).then((res: any) => {
                setUploading(false);
                if (res) {
                    message.success('Import success')
                } else {
                    message.error('Import fail')
                }
            }).catch((err: any) => {
                message.error('Import fail')
                setUploading(false);
            });
        } else {
            message.error('Please connect wallet')
        }
    };

    const getListWhiteList = async () => {
        let params: any = {
            pageIndex: 0,
            pageSize: 0,
            address: "",
            type: -1,
        };
        await AquaAdmin.getWhitelist(params).then((res: any) => {
            if (res.data.isSuccess === false) {
                navigate(`/login`)
            } else {
                if (res.data.data) {
                    setCount(res.data.totalCount)
                    setData(res.data.data)
                }
            }
        }).catch((err: any) => {
            console.log('err', err)
        });
    }

    const handleWallet = (e: any) => {
        setValueWallet(e.target.value)
    }

    const handleAmount = (e: any) => {
        setValueAmount(e.target.value)
    }

    const handleHash = (e: any) => {
        setValueHash(e.target.value)
    }

    const handleAction = (value: any) => {
        setValueAction(value)
    }

    const handleConfirm = async () => {
        let valueParse
        switch (valueAction) {
            case "true":
                valueParse = true;
                break;
            case "fasle":
                valueParse = false;
                break;
        }
        let params = {
            ownerAddress: wallets,
            address: valueWallet,
            amount: Number(valueAmount),
            txHash: valueHash,
            isActive: valueParse
        }
        await AquaAdmin.postWhitelistByHand(params).then((res: any) => {
            if (res.data.data) {
                setCount(res.data.totalCount)
                setData(res.data.data)
                window.location.reload()
            }
        }).catch((err: any) => {
            console.log('err', err)
        });
    }

    useEffect(() => {
        getListWhiteList()
    }, []);

    const onChange: PaginationProps['onChange'] = async (page) => {
        setCurrent(page);
        let params: any = {
            pageIndex: page - 1,
            pageSize: 0,
            address: "",
            type: -1,
        };
        await AquaAdmin.getWhitelist(params).then((res: any) => {
            if (res.data.isSuccess === false) {
                navigate(`/login`)
            } else {
                if (res.data.data) {
                    setCount(res.data.totalCount)
                    setData(res.data.data)
                }
            }
        }).catch((err: any) => {
            console.log('err', err)
        });
    };

    const handleSearch = async (value: any) => {
        let params: any = {
            pageIndex: 0,
            pageSize: 0,
            address: value,
            type: -1,
        };
        await AquaAdmin.getWhitelist(params).then((res: any) => {
            if (res.data.isSuccess === false) {
                navigate(`/login`)
            } else {
                if (res.data.data) {
                    setCount(res.data.totalCount)
                    setData(res.data.data)
                }
            }
        }).catch((err: any) => {
            console.log('err', err)
        });
    }

    const changeSelect = async (value: any) => {
        setValueSelect(value)
        localStorage.setItem("defaultSelect", value)
        let params: any = {
            pageIndex: 0,
            pageSize: 0,
            address: "",
            type: Number(value),
        };
        await AquaAdmin.getWhitelist(params).then((res: any) => {
            if (res.data.isSuccess === false) {
                navigate(`/login`)
            } else {
                if (res.data.data) {
                    setCount(res.data.totalCount)
                    setData(res.data.data)
                }
            }
        }).catch((err: any) => {
            console.log('err', err)
        });
    }

    let valueInit = localStorage.getItem("defaultSelect")

    const Item = ({ item, index }: any) => {

        const [isModalOpen, setIsModalOpen] = useState(false);

        const showModal = () => {
            setIsModalOpen(true);
        };

        const handleOk = () => {
            setIsModalOpen(false);
        };

        const handleCancel = () => {
            setIsModalOpen(false);
        };

        const handleHistory = async () => {
            navigate(`history?${item?.ownerAddress}`)
        };

        const [valueAmountModal, setValueAmountModal] = useState("")
        const [valueStatusModal, setValueStatusModal] = useState("")
        const [valueHashModal, setValueHashModal] = useState("")

        const handleWalletModal = (e: any) => {
            setValueAmountModal(e.target.value)
        }

        const handleStatusModal = (value: any) => {
            setValueStatusModal(value)
        }

        const handleHashModal = (e: any) => {
            setValueHashModal(e.target.value)
        }

        const handleUpdateAction = async () => {
            let valueParseActive
            switch (valueStatusModal) {
                case "true":
                    valueParseActive = true;
                    break;
                case "fasle":
                    valueParseActive = false;
                    break;
            }
            let params = {
                ownerAddress: wallets,
                id: item.id,
                address: item?.ownerAddress,
                amount: Number(valueAmountModal),
                txHash: valueHashModal,
                isActive: valueParseActive,
            }
            await AquaAdmin.postUpdateStatus(params).then((res: any) => {
                if (res.data.data) {
                    setCount(res.data.totalCount)
                    setData(res.data.data)
                    window.location.reload()
                }
            }).catch((err: any) => {
                console.log('err', err)
            });
        }

        const onSearch: SearchProps['onSearch'] = (value: any) => {
            console.log('value', value)
        }

        return (
            <>
                <tr>
                    <td>
                        {index + 1}
                    </td>
                    <td>
                        {item.ownerAddress?.substring(0, 4)}...{item.ownerAddress?.substring(item.ownerAddress.length - 4)}
                    </td>
                    <td>
                        {item.tokenClaim}
                    </td>
                    <td>
                        {item?.totalReward}
                    </td>
                    <td>
                        {moment(item.createOn).format('MMMM Do YYYY, h:mm:ss')}
                    </td>
                    <td>
                        {item.lastUpdateReward}
                    </td>
                    <td>
                        {item.txHash === null ? "N/A" : `${item.txHash?.substring(0, 4)}...${item.txHash?.substring(item.txHash.length - 4)}`}
                    </td>
                    <td>
                        {item.isActive ?
                            <div className="checked">
                                <CheckCircleOutlined />
                            </div>
                            :
                            <div className="closed" onClick={handleUpdateAction}>
                                <CloseCircleOutlined />
                            </div>
                        }
                    </td>
                    <td>
                        <div className="button-action">
                            <button className="btn-action" onClick={handleHistory}>
                                <HistoryOutlined />
                            </button>
                        </div>
                    </td>
                    <td>
                        <div className="button-action">
                            <button className="btn-action" onClick={showModal}>
                                <EditOutlined />
                            </button>
                        </div>
                    </td>
                </tr>
                <Modal title="Edit whitelist" open={isModalOpen} className="modal-edit" onOk={handleOk} onCancel={handleCancel}>
                    <div className="row-modal-add-wl">
                        <div className="item">
                            <div className="txt">
                                Address:
                            </div>
                            <div className="input-modal">
                                <input type="text" value={`${item.ownerAddress?.substring(0, 4)}...${item.ownerAddress?.substring(item.ownerAddress.length - 4)}`} placeholder="address" className="f-controls" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="txt">
                                Amount:
                            </div>
                            <div className="input-modal">
                                <input type="text" onChange={handleWalletModal} placeholder={item.tokenClaim} className="f-controls" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="txt">
                                TxHash:
                            </div>
                            <div className="input-modal">
                                <input type="text" onChange={handleHashModal} placeholder={item.txHash === null ? "N/A" : `${item.txHash?.substring(0, 4)}...${item.txHash?.substring(item.txHash.length - 4)}`} className="f-controls" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="txt">
                                Status:
                            </div>
                            <div className="row-select">
                                <Select
                                    value={valueStatusModal ? valueStatusModal : "Select status"}
                                    onChange={handleStatusModal}
                                    placeholder="Select status"
                                    suffixIcon={<CaretDownOutlined rev={undefined} />}
                                >
                                    <Option value="true">
                                        <span className="text-chain">Active</span>
                                    </Option>
                                    <Option value="false">
                                        <span className="text-chain">Close</span>
                                    </Option>
                                </Select>
                            </div>
                            {/* <div className="input-modal">
                                <input type="text" onChange={handleStatusModal} placeholder="Status: true / false" className="f-controls" />
                            </div> */}
                        </div>
                        <div className="gr-button">
                            {valueAmountModal !== "" && valueStatusModal !== "" ?
                                <>
                                    <button className="btn-edit" style={{ marginRight: "10px" }} onClick={handleUpdateAction}>
                                        Confirm
                                    </button>
                                </>
                                :
                                <>
                                    <button className="btn-edit dis" style={{ marginRight: "10px" }}>
                                        Confirm
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </Modal>
            </>
        )
    }

    return (
        <>
            <div className="wrap-container">
                <div className="row-import">
                    <div className="guide-import row">
                        <div className="title">
                            Import whitelist
                        </div>
                        <div className="row-add-wl">
                            <div className="example-wl">
                                <a href="https://docs.google.com/spreadsheets/d/1wAGdMqEw6PJoa71otGulPjmeRvE9urAt6UYwAQ7hrpg/edit#gid=0" target='blank'>
                                    Example whitelist <DownloadOutlined />
                                </a>
                            </div>
                            <div className="row-choose-file">
                                <Upload accept="*" {...props}>
                                    <Button icon={<UploadOutlined />}>Select File</Button>
                                </Upload>
                            </div>
                            <div className="row-start-up">
                                <Button
                                    type="primary"
                                    onClick={handleUpload}
                                    disabled={fileList.length === 0}
                                    loading={uploading}
                                >
                                    {uploading ? 'Uploading' : 'Start Upload'}
                                </Button>
                            </div>
                        </div>
                        <div className="row-data-wl">
                            <div className="text">
                                Import whitelist
                            </div>
                            <div className="round-add-by-hand">
                                <div className="columns">
                                    <div className="colum w-3">
                                        <div className="row-input">
                                            <div className="round-name">
                                                Address
                                            </div>
                                            <input type="text" onChange={handleWallet} placeholder="address" className="f-control" />
                                        </div>
                                    </div>
                                    <div className="colum w-3">
                                        <div className="row-input">
                                            <div className="round-name">
                                                Amount
                                            </div>
                                            <input type="text" onChange={handleAmount} placeholder='amount' className="f-control" />
                                        </div>
                                    </div>
                                    <div className="colum w-3">
                                        <div className="row-input">
                                            <div className="round-name">
                                                TxHash
                                            </div>
                                            <input type="text" onChange={handleHash} placeholder='hash' className="f-control" />
                                        </div>
                                    </div>
                                    <div className="colum w-3">
                                        <div className="row-input">
                                            <div className="round-name">
                                                Status
                                            </div>
                                            <div className="row-select">
                                                <Select
                                                    value={valueAction ? valueAction : "Select status"}
                                                    onChange={handleAction}
                                                    placeholder="Select status"
                                                    suffixIcon={<CaretDownOutlined rev={undefined} />}
                                                >
                                                    <Option value="true">
                                                        <span className="text-chain">Active</span>
                                                    </Option>
                                                    <Option value="false">
                                                        <span className="text-chain">Close</span>
                                                    </Option>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="gr-button">
                                    {valueWallet !== "" && valueAmount !== "" && valueAction !== "" ?
                                        <>
                                            <button className="btn-edit" onClick={handleConfirm}>
                                                Confirm
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button className="btn-edit dis">
                                                Confirm
                                            </button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row-data-wl">
                            <div className="text">
                                Address List
                            </div>
                            <div className="round-filter">
                                <div className="row-search">
                                    <Search placeholder="Search wallet" onSearch={handleSearch} enterButton="Search" size="large" />
                                </div>
                                <div className="row-select">
                                    <Select
                                        value={valueInit}
                                        onChange={changeSelect}
                                        placeholder="Filter to Select"
                                        suffixIcon={<CaretDownOutlined rev={undefined} />}
                                    >
                                        <Option value="-1">
                                            <span className="text-chain">All status</span>
                                        </Option>
                                        <Option value="1">
                                            <span className="text-chain">Active</span>
                                        </Option>
                                        <Option value="0">
                                            <span className="text-chain">Un active</span>
                                        </Option>
                                    </Select>
                                </div>
                            </div>
                            {data.length > 0 ?
                                <>
                                    <div className="data-table">
                                        <table className="main-table">
                                            <tr>
                                                <th>
                                                    ID
                                                </th>
                                                <th>
                                                    Wallet
                                                </th>
                                                <th>
                                                    Amount
                                                </th>
                                                <th>
                                                    Total reward
                                                </th>
                                                <th>
                                                    Time
                                                </th>
                                                <th>
                                                    Update time
                                                </th>
                                                <th>
                                                    txHash
                                                </th>
                                                <th>
                                                    Status
                                                </th>
                                                <th>
                                                    History
                                                </th>
                                                <th>
                                                    Action
                                                </th>
                                            </tr>
                                            {data && data.map((item: any, index: any) => (
                                                <Item item={item} index={index} />
                                            ))}
                                        </table>
                                        {count < 10 ?
                                            <>
                                                {""}
                                            </>
                                            :
                                            <>
                                                <Pagination onChange={onChange} current={current} total={count} />
                                            </>
                                        }
                                    </div>
                                </>
                                :
                                <>
                                    <div className="non-data">
                                        <Empty />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ImportWL