import React , {useMemo} from 'react';
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import LoginPage from './pages/login';
import Setting from './pages/setting';
import EditSetting from './pages/setting/editSetting';
import Dashboard from './pages/dashboard';
import HistoryVesting from './pages/importWl/details';
import './App.css';
import Main from './components/main';
import ImportWL from './pages/importWl';
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { clusterApiUrl } from "@solana/web3.js";
import {
    PhantomWalletAdapter
} from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import PoolInfo from './pages/pool-info';
require("@solana/wallet-adapter-react-ui/styles.css");

function App() {
    const network = WalletAdapterNetwork.Mainnet;
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter()
        ],
        [network]
    );

    return (
        <div className="App">
            <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect>
                    <WalletModalProvider>
                        <Main>
                            <Routes>
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/import-wl" element={<ImportWL />} />
                                <Route path="/import-wl/history" element={<HistoryVesting />} />
                                <Route path="/edit-setting" element={<EditSetting />} />
                                <Route path="/setting" element={<Setting />} />
                                <Route path="/pool-info" element={<PoolInfo />} />
                            </Routes>
                        </Main>
                    </WalletModalProvider>
                </WalletProvider>
            </ConnectionProvider>
        </div>
    );
}

export default App;
