import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import uuid from "uuid";

function getUrl(config: any) {
    if (config.baseURL) {
        return config.url.replace(config.baseURL, "").split("?")[0];
    }
    return config.url;
}

let cookieHeader = localStorage.getItem("tokenAccess")
let deviceUser = uuid.v4()

export const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: "application/json",
        "Token-Authentication": cookieHeader,
        "Device-Authentication": deviceUser
    },
});

instance.interceptors.request.use(
    async (config: any) => {
        //@ts-ignore
        // console.log(`%c ${config.method.toUpperCase()} - ${config.url}:`, 'color: #0086b3; font-weight: bold', config);

        return config;
    },
    (error: any) => {
        // console.log(`%c ${error.response.status}  :`, 'color: red; font-weight: bold', error.response.data);
        return Promise.reject(error);
    }
);


instance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    function (error) {
        if (error.response) {
            return Promise.reject({
                status: error.response.status,
                message: error.response.data.message,
            });
        } else if (error.request) {
            return Promise.reject(error.request);
        } else {
            return Promise.reject(error);
        }
    }
);
