import React, { useState , useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { AquaAdmin } from '../../api/aquaApi';
import uuid from "uuid";
import "./style.scss"

const LoginPage = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleChangeEmai = (e: any) => {
        setEmail(e.target.value)
    }

    const handleChangePass = (e: any) => {
        setPassword(e.target.value)
    }

    let deviceUser = uuid.v4()
    console.log('deviceUser', deviceUser)

    const handleLogin = async () => {
        await AquaAdmin.loginAdmin(email , password ).then((res: any) => {
            if (res.status == 200 && res.data.data) {
                if (res.data.data.tokenAccess) {
                    localStorage.setItem("tokenAccess", res.data.data.tokenAccess);
                    localStorage.setItem("userName", res.data.data.name);
                } else {
                    localStorage.setItem("tokenAccess", "undefined");
                    localStorage.setItem("userName", "undefined");
                }
                navigate(`/`)
                window.location.reload()
            }
        }).catch((err: any) => {
            console.log('err', err)
        });
    }

    return (
        <>
            <div className="wrap-login">
                <div className="row-login">
                    <div className="columns">
                        <div className="colum w-6">
                            <div className="content-row">
                                <div className="box-img">
                                    <img src="./images/login-img.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="colum w-6">
                            <div className="content-row">
                                <div className="form-login">
                                    <div className="title">
                                        Member Login
                                    </div>
                                    <div className="input-form">
                                        <input placeholder="Email" type="text" onChange={handleChangeEmai} className="form-control" />
                                    </div>
                                    <div className="input-form">
                                        <input placeholder="Password" type="password" onChange={handleChangePass} className="form-control" />
                                    </div>
                                    <div className="input-form">
                                        <button className="btn-login" onClick={handleLogin}>
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LoginPage