import React from "react";
import { Layout } from "antd";
import Header from "../Header";
import RoleAdmin from "../Role";
import { useLocation } from "react-router-dom";
import "../style.scss"

const { Content } = Layout;
function Main({ children, chainHeader }: any) {
    const location = useLocation()
    const pathURL = location.pathname.split("/")
    const pathLive = pathURL[1]

    return (
        <Layout>
            {pathLive === "login" ?
                <>
                    <div className="flex-content">
                        <Content>{children}</Content>
                    </div>
                </>
                :
                <>
                    <div className="flex-row">
                        <Header />
                        <div className="flex-content">
                            <RoleAdmin />
                            <div className="flex-content-row">
                                <div className="layout-row">
                                    <Content>{children}</Content>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </Layout >
    );
}

export default Main;