import React, { useState, useEffect } from 'react';
import "./style.scss"

const Dashboard = () => {
    return (
        <>
            <div className="title-ds">
                Welcom admin Aquacity
            </div>
        </>
    )
}
export default Dashboard